import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import Nav from 'src/layout/Nav';
import Footer from 'src/layout/Footer';

import 'src/stylesheets/main.scss';

const Layout = ({ className, children }) => {
  return (
    <div className="app">
      <SkipNavLink className="visually-hidden" />
      <Nav />
      <SkipNavContent />
      <main className={classNames({ [className]: className })}>{children}</main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Layout;
