import React from 'react';
import * as PDFJS from 'pdfjs-dist';
import 'src/stylesheets/text_layer_builder.css';

function MagazinePage(props) {
  let { handle, viewport, textContent } = props.pageReference;

  React.useEffect(() => {
    if (textContent) {
      // Select the text layer, clear it out
      let container = document.getElementById(props.pageName);
      container.innerHTML = '';
      // Set the text layer's size and offset based on extracted values from the canvas
      let canvas = handle.current;
      container.style.width = canvas.width + 'px';
      container.style.height = canvas.height + 'px';
      container.style.left = canvas.offsetLeft + 'px';
      container.style.top = canvas.offsetTop + 'px';

      // Attempt to perform text layer's render call
      try {
        PDFJS.renderTextLayer({
          textContent,
          container,
          viewport,
          textDivs: [],
        });
      } catch (err) {
        console.error(`Could not render text layer:\n${err}`);
      }
    }
  });

  return (
    <>
      <canvas
        className={props.mobileView ? 'magazine-page' : 'magazine-mobile-hidden'}
        ref={handle}></canvas>
      {textContent ? <div id={props.pageName} className="textLayer"></div> : ''}
    </>
  );
}

export default MagazinePage;
