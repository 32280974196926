import React from 'react';

const ProgressBar = props => {
  return (
    <div className="progress-bar-container">
      <h4>
        Downloading PDF:{' '}
        {typeof props.progress === 'number'
          ? props.progress.toFixed(0).toString() + '%'
          : 'Please Wait...'}
      </h4>
      <div className="progress progress-bar-fill">
        <div style={{ width: `${props.progress}%` }} className="progress-bar"></div>
      </div>
    </div>
  );
};

export default ProgressBar;
