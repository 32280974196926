/*
RENDER DECLARATIONS

The actual function definitions for static and dynamic rendering/fetching
These will be imported by the RenderTaskManager.
The proper functions are assigned during construction and are immutable.

Dynamic: "fetchDocument" pulls the PDF from the specified URL.
  "renderPage" uses PDF.js to render the image and text layer.
Static: "fetchDocument" pulls the database entry containing links to each page's data
  "renderPage" fetches that page from the extracted URL and 
*/

import * as PDFJS from 'pdfjs-dist';
import PDFJSWorker from 'pdfjs-dist/build/pdf.worker.js';

if (typeof window !== 'undefined') {
  PDFJS.GlobalWorkerOptions.workerPort = new PDFJSWorker();
}

function loadDynamicRenderer(docUrl, progressBarCallback) {
  let loader = PDFJS.getDocument(docUrl);
  loader.onProgress = ({ loaded, total }) => {
    if (typeof loaded === 'number' && typeof total === 'number') {
      progressBarCallback((loaded / total) * 100);
    }
  }

  return loader.promise.then(pdf => new DynamicRenderer(pdf, pdf.numPages));
}

class DynamicRenderer {
  constructor(pdfDocument, numPages) {
    this.pdfDocument = pdfDocument;
    this.numPages = numPages;
    this.canvas = document.createElement('canvas');
  }

  renderPage(pageNum) {
    return new Promise((resolve, reject) => {
      let pageLoadTask = this.pdfDocument.getPage(pageNum);
      pageLoadTask
        .then(page => {
          let viewport = page.getViewport({ scale: 2.0 });
          let canvasContext = this.canvas.getContext('2d');
          this.canvas.width = viewport.width;
          this.canvas.height = viewport.height;

          let renderTask = page.render({
            canvasContext,
            viewport
          }).promise;
          renderTask
            .then(() => {
              page
                .getTextContent()
                .then(textData => {
                  this.canvas.toBlob(function(blob) {
                    let img = document.createElement('img');
                    img.src = URL.createObjectURL(blob);
                    img.onload = function() {
                      resolve({ pageNum, imageData: img, textData, viewport });
                    };
                  }, 'image/jpeg');
                })
                .catch(err => {
                  if (typeof window === 'object') reject(err);
                })
                .finally(() => {
                  page.cleanup();
                });
            })
            .catch(err => {
              if (typeof window === 'object') reject(err);
            });
        })
        .catch(function(err) {
          if (typeof window === 'object') reject(err);
        });
    });
  }

  cleanup() {
    this.pdfDocument.cleanup();
    this.pdfDocument.destroy();
  }
}

export {
  loadDynamicRenderer,
};
