import React from 'react';
import Layout from 'src/layout/Layout';
import { graphql } from 'gatsby';

import Magazine from '../components/release/Magazine.js';

export default function Release({ pageContext }) {
  const { release } = pageContext;
  return (
    <Layout>
      <div>
        <Magazine src={release.pdf} />
      </div>
    </Layout>
  );
}
