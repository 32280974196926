/*
RENDER TASK MANAGER CLASS

Loads the document and schedules rendering tasks.
Pulls the Dynamic and Static page rendering/fetching from outside classes
*/

/*
TODO:
- Implement a "priority" or "sticky" element, to allow certain tasks to always be called first
  For example, user-requested renders should be prioritized over cache renders
*/

import { loadDynamicRenderer } from './render';

const createSubscription = function() {
  let item = {};
  item.promise = new Promise(
    function(resolve, reject) {
      this.resolve = resolve;
      this.reject = reject;
    }.bind(item)
  );
  return item;
};

function loadRenderTaskManager(docUrl, progressBarCallback) {
  return loadDynamicRenderer(docUrl, progressBarCallback)
    .then(renderer => new RenderTaskManager(renderer));
}

class RenderTaskManager {
  constructor(renderer) {
    this._render = renderer;
    this.taskQueue = new Set();
    this.subscriptions = new Map();
    this.batchRunning = null;
    this.threadRunning = false;
    this.numPages = renderer.numPages;
  }

  cleanup() {
    this._render.cleanup();
  }

  addRenderTask(pageNum) {
    this.taskQueue.add(pageNum);
    return this.subscribe(pageNum);
  }

  subscribe(pageNum) {
    if (!this.subscriptions.has(pageNum)) {
      this.subscriptions.set(pageNum, createSubscription());
    }
    return this.subscriptions.get(pageNum).promise;
  }

  subscribeAll() {
    return this.batchRunning
      ? this.batchRunning.promise
      : Promise.reject(`No rendering in process`);
  }

  execute() {
    // Sets the batchRunning subscription and adds a subscription tag
    if (!this.batchRunning) {
      this.batchRunning = createSubscription();
    }
    if (!this.threadRunning && this.taskQueue.size > 0) {
      this.threadRunning = true;
      let task = this.taskQueue.values().next();
      this.taskQueue.delete(task.value);
      this._render
        .renderPage(task.value)
        .then(({ pageNum, imageData, textData, viewport }) => {
          let subscription = this.subscriptions.get(pageNum);
          if (subscription) {
            subscription.resolve({ imageData, textData, viewport });
            this.subscriptions.delete(pageNum);
          }
          this.threadRunning = false;
          this.execute();
        })
        .catch(err => {
          this.subscriptions.get(task.value).reject(err);
        });
    } else {
      this.batchRunning.resolve();
      this.batchRunning = null;
    }
  }
}

export {
  loadRenderTaskManager,
};
