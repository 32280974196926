import React from 'react';

const PageTurnButton = function(props) {
  let points = '';

  // Set SVG shape based on which direction is given
  // If it can't be determined, return a default, unstyled button
  switch (props.direction) {
    case 'left':
      // points = '45.63,75.8 0.375,38.087 45.63,0.375 45.63,75.8';
      points = '25,0 0,30 25,60';
      break;
    case 'right':
      // points = '0.375,0.375 45.63,38.087, 0.375, 75.8 0.375,0.375';
      points = '0,0 25,30 0,60';
      break;
    default:
      return <button onClick={props.onClick}>{props.direction}</button>;
  }

  return (
    <button
      className={`magazine-btn ${props.direction} ${
        props.mobileView ? '' : 'magazine-mobile-hidden'
      }`}
      onClick={props.onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="40px"
        height="80px"
        viewBox="0 0 25 60"
        xmlSpace="preserve">
        <polyline
          fill="none"
          stroke="#303030a0"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points={points}
        />
      </svg>
    </button>
  );
};

export default PageTurnButton;
