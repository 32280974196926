import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer>
    <Link to="/about" aria-label="About Meme Insider" className="footer-link">
      About
    </Link>
    <a href="/tos" className="footer-link">
      Terms of Service
    </a>
    <a href="/privacy" className="footer-link">
      Privacy Policy
    </a>
    <Link to="/contact" className="footer-link">
      Contact
    </Link>
    <Link to="/faq" aria-label="Meme Insider FAQs" className="footer-link">
      FAQs
    </Link>
  </footer>
);

export default Footer;
