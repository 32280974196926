import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

const navLinks = [
  { href: '/releases', text: 'Releases' },
  {
    href: '/articles',
    text: 'Articles',
    className: 'coming-soon',
    tabIndex: -1,
  },
  { href: '/positions', text: 'Join Our Team' },
  {
    href: 'https://memeinsider.memberful.com/join',
    text: 'Subscribe',
    className: 'accent',
  },
];

const Nav = () => (
  <nav>
    <Link to="/" className="logo-nav">
      <span className="desktop">
        <div>Meme Insider</div>
        <div>
          <p>a Know Your Meme publication</p>
        </div>
      </span>
      <span className="mobile">MI</span>
    </Link>
    <div className="nav-links">
      {navLinks.map(({ href, text, className, ...rest }, i) => {
        const isExternalLink = href.includes('http');
        const Tag = isExternalLink ? 'a' : Link;
        const linkProps = {
          className: classNames('nav-link', { [className]: className }),
          ...(isExternalLink
            ? { href }
            : { to: href, activeClassName: 'active', partiallyActive: true }),
          ...rest,
        };

        return (
          <Tag key={i} {...linkProps}>
            {text}
          </Tag>
        );
      })}
    </div>
  </nav>
);

export default Nav;
