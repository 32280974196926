import React from 'react';

import { loadRenderTaskManager } from './util/manager';
import PageTurnButton from './PageTurnButton';
import MagazinePage from './MagazinePage';
import ProgressBar from './ProgressBar';

/*
STILL LEFT TO DO:
1. Add CSS classes and .next/prevMobile() so it works on phones
---
Bonus:
1. Modify canvas layout so there's 2 in front, 2 behind
	When rendering, render to the "hidden" canvas then swap them
2. Add transition animations when swapping from "hidden" to "shown" canvas
*/

class Magazine extends React.Component {
  constructor(props) {
    super(props);

    this.page = {
      left: {
        handle: React.createRef(),
        textContent: null,
        viewport: null,
      },
      right: {
        handle: React.createRef(),
        textContent: null,
        viewport: null,
      },
      // An image that isn't displayed on-screen, used only in resizing
      renderUtil: typeof document === 'object' && document.createElement('img'),
    };

    this.cache = new Map();
    this.cacheMobile = new Map();
    this.cacheProcess = new Map();

    this.state = {
      renderer: null,
      rendering: false,
      pageNum: { left: 0, right: 1 },
      mobile: false,
      mobileView: {
        left: false,
        right: true,
      },
      pageCount: 0,
      cacheMax: 20,
      defaultSize: { width: 0, height: 0 },
      resizeFactor: this.props.resizeFactor || 0.66,
      swipe: 0,
      progress: 0,
    };

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.nextPageMobile = this.nextPageMobile.bind(this);
    this.prevPageMobile = this.prevPageMobile.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.handleRelease = this.handleRelease.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
  }

  /* RENDER METHODS */
  // Queues the provided array of page numbers for rendering and caching
  // You'll have to execute this.renderer.execute() externally
  cachePage(pageNum) {
    if (pageNum < 1 || pageNum > this.state.pageCount) {
      return Promise.reject(`Page ${pageNum} out of range`);
    }
    let subscription = this.state.renderer.addRenderTask(pageNum);
    subscription = subscription
      .then(pageData => {
        this.cache.set(pageNum, pageData);
        this.cacheProcess.delete(pageNum);
      })
      .catch(err => {
        console.error(err);
      });
    this.cacheProcess.set(pageNum, subscription);
    return subscription;
  }

  // Loads the provided page from cache and renders it to the given canvas
  // Returns true for a cache hit, false for a cache miss
  // You should test for this; you'll need to call renderer.execute() manually
  renderPage(pageNum, pageData) {
    // Ready the canvas
    let canvas = pageData.handle.current;
    let cacheHit = this.cache.has(pageNum);
    let process;
    // Pulls exclusively from the cache
    if (pageNum > 0 && pageNum <= this.state.pageCount) {
      process = cacheHit ? Promise.resolve() : this.cacheProcess.get(pageNum);
      process.then(() => {
        if (this.cache.has(pageNum)) {
          let { imageData } = this.cache.get(pageNum);
          this.paintCanvas(imageData, canvas);
        }
      });
    } else {
      process = this.clearCanvas(canvas)
        .then(() => {
          pageData.textContent = null;
          pageData.viewport = null;
        })
        .catch(err => {
          console.error(err);
        });
    }
    return { cacheHit, process };
  }

  paintCanvas(imageData, canvas) {
    let context = canvas.getContext('2d');
    return new Promise((resolve) => {
      // Extract the image from the blob
      canvas.width = imageData.width;
      canvas.height = imageData.height;
      context.drawImage(imageData, 0, 0);
      resolve();
    });
  }

  clearCanvas(canvas) {
    let context = canvas.getContext('2d');
    return new Promise(resolve => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = this.state.defaultSize.width;
      canvas.height = this.state.defaultSize.height;
      resolve();
    });
  }

  /* PAGE TURN METHODS */
  nextPage() {
    if (!this.state.rendering && this.state.pageNum.right < this.state.pageCount) {
      this.setState(state => {
        let { left, right } = state.pageNum;
        left += 2;
        right += 2;
        return { rendering: true, pageNum: { left, right }, meta: { ...state.meta } };
      });
    }
  }

  prevPage() {
    if (!this.state.rendering && this.state.pageNum.left > 1) {
      this.setState(state => {
        let { left, right } = state.pageNum;
        left -= 2;
        right -= 2;
        return { rendering: true, pageNum: { left, right } };
      });
    }
  }

  toggleMobilePages() {
    this.setState(state => {
      let { left, right } = state.mobileView;
      return { mobileView: { left: !left, right: !right } };
    });
  }

  nextPageMobile() {
    if (!this.state.rendering && this.state.pageNum.right < this.state.pageCount) {
      // Extract the current mobile definition
      let { right } = this.state.mobileView;

      // Toggle the mobile viewer
      // If the mobile view was showing the right-hand page, then a full page turn is needed
      this.toggleMobilePages();
      if (right) {
        this.nextPage();
      }
    }
  }

  prevPageMobile() {
    if (!this.state.rendering && this.state.pageNum.left > 1) {
      // Extract the current mobile definition
      let { left } = this.state.mobileView;

      // Toggle the mobile viewer
      // If the mobile view was showing the left-hand page, then a full page turn is needed
      this.toggleMobilePages();
      if (left) {
        this.prevPage();
      }
    }
  }

  handleSwipe(event) {
    this.setState({ swipe: event.changedTouches[0].pageX });
    event.persist();
  }

  handleRelease(event) {
    let result = this.state.swipe - event.changedTouches[0].pageX;
    // Don't register swipe if the swipe was under 100px
    if (Math.abs(result) < 25) return;
    if (result > 0) {
      if (this.state.mobile) {
        this.nextPageMobile();
      } else {
        this.nextPage();
      }
    } else {
      if (this.state.mobile) {
        this.prevPageMobile();
      } else {
        this.prevPage();
      }
    }
  }

  updateProgress(newProgress) {
    this.setState({ progress: newProgress });
  }

  /* REACT METHODS */
  componentDidMount() {
    // Define an event listener which toggles visibility based on whether it's mobile or not
    const media = window.matchMedia('(max-width: 1200px)');
    media.addListener(event => {
      if (event.matches) {
        // Consider "mobile"
        this.setState({ mobile: true });
      } else {
        // Consider "desktop"
        this.setState({ mobile: false });
      }
    });

    loadRenderTaskManager(this.props.src, this.updateProgress.bind(this))
      .then(renderer => {
        this.setState({
          renderer: renderer,
          isReady: true,
          rendering: true,
          mobile: media.matches,
          pageCount: renderer.numPages,
          cacheMax: renderer.numPages,
        });
      });
  }

  componentWillUnmount() {
    if (this.state.renderer) {
      this.state.renderer.cleanup();
    }
    this.cache.clear();
    this.cacheProcess.clear();
  }

  componentDidUpdate(prevProps, prevState) {
    // Extract the current page numbers and define the cache floor and ceiling
    let { right } = this.state.pageNum;
    // Cache Floor: Minimum valid page
    // let cacheFloor = Math.max(this.meta.cacheMax - left, 1);
    // Cache Cieling: Maximum valid page
    let cacheCeiling = Math.min(this.state.cacheMax + right, this.state.pageCount);
    let i,
      needsRefresh = false;
    for (i = right; i <= cacheCeiling; i += 1) {
      let page = i;
      if (!this.cache.has(page) && !this.cacheProcess.has(page)) {
        needsRefresh = true;
        this.cachePage(page).catch(err => {
          console.error(`Failed to cache page ${page}\n${err}`);
        });
      }
    }
    if (needsRefresh && this.state.renderer) this.state.renderer.execute();

    if (this.state.rendering) {
      let leftPageTask = this.renderPage(this.state.pageNum.left, this.page.left);
      let rightPageTask = this.renderPage(this.state.pageNum.right, this.page.right);
      let batchTask = Promise.all([leftPageTask.process, rightPageTask.process]);
      batchTask
        .then(() => {
          this.setState({ rendering: false });
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  render() {
    let prevHandler = this.state.mobile ? this.prevPageMobile : this.prevPage;
    let nextHandler = this.state.mobile ? this.nextPageMobile : this.nextPage;
    return (
      <>
        {!this.state.isReady && <ProgressBar progress={this.state.progress} />}
        {this.state.mobile && (
          <div style={{ display: 'flex', width: '100%' }}>
            <button className="magazine-btn-mobile left" onClick={this.prevPageMobile}>
              PREV
            </button>
            <button className="magazine-btn-mobile right" onClick={this.nextPageMobile}>
              NEXT
            </button>
          </div>
        )}
        <div className="magazine-viewer">
          <div className="magazine-btn-container left">
            <PageTurnButton
              onClick={prevHandler}
              direction="left"
              mobileView={this.state.isReady && !this.state.mobile}
            />
          </div>
          <div
            className="magazine-page-container"
            onTouchStart={this.handleSwipe}
            onTouchEnd={this.handleRelease}>
            <MagazinePage
              pageReference={this.page.left}
              pageName="magazine-page-left"
              mobileView={
                this.state.pageNum.left > 0 && (!this.state.mobile || this.state.mobileView.left)
              }
            />
            <MagazinePage
              pageReference={this.page.right}
              pageName="magazine-page-right"
              mobileView={
                this.state.pageNum.right <= this.state.pageCount &&
                (!this.state.mobile || this.state.mobileView.right)
              }
            />
          </div>
          <div className="magazine-btn-container right">
            <PageTurnButton
              onClick={nextHandler}
              direction="right"
              mobileView={this.state.isReady && !this.state.mobile}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Magazine;
